import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "react-query";
import { Outlet, useNavigate } from "react-router-dom";
import { AppProvider } from "@toolpad/core/react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { postRequestUI, getRequestUI } from "common-utils/utils/api";
import { createTheme } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsightsIcon from "@mui/icons-material/Insights";

const SEGMENTS = {
  LOCATIONS: "locations",
  SHIPPING_INTELLIGENCE_HEADER: "shippingIntelligenceHeader",
  OVERVIEW: "overview",
  BENCHMARKS: "benchmarks",
};

const NAVIGATION = [
  {
    kind: "header",
    title: "Shipments",
  },
  {
    segment: "orders",
    title: "Orders",
    icon: <DashboardIcon />,
  },
  {
    segment: "delivery",
    title: "New Shipment",
    icon: <LocalShippingIcon />,
  },
  {
    segment: "claims",
    title: "Claims",
    icon: <AssignmentIcon />,
  },
  {
    segment: SEGMENTS.LOCATIONS,
    title: "Locations",
    icon: <LocationOnIcon />,
  },
  {
    segment: SEGMENTS.SHIPPING_INTELLIGENCE_HEADER,
    kind: "header",
    title: "Shipping Intelligence",
  },
  {
    segment: SEGMENTS.OVERVIEW,
    title: "Overview",
    icon: <InsightsIcon />,
  },
  {
    segment: SEGMENTS.BENCHMARKS,
    title: "Benchmarks",
    icon: <InsightsIcon />,
  },
];

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function RetailerApp() {
  const [auth, setAuth] = useLocalStorage("auth", null);
  const [userClaims, setUserClaims] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      authUser();
    } else {
      cleanState();
    }
  }, [auth]);

  const cleanState = () => {
    setUserClaims({});
  };

  const signOut = async () => {
    try {
      await postRequestUI("/logout");
    } catch (error) {
      console.error("Logout failed:", error);
    }
    cleanState();
    setAuth(null);
    navigate("/login", { replace: true });
  };

  const authUser = async () => {
    try {
      const { role, email } = auth;
      if (!role?.userType) {
        signOut();
        return;
      }
      if (!["retailer", "storeManager"].includes(role.userType)) {
        signOut();
        return;
      }
      setUserClaims({
        userType: role.userType,
        retailerId: role.retailerId,
        locationId: role.defaultLocationId,
        email: email,
      });

      setTimeout(function () {
        window.Trengo.contact_data = {
          email: email,
          name: email,
          custom_fields: [
            {
              field_id: email,
              user_email: email,
            },
          ],
        };
        if (window.Trengo.Api && window.Trengo.Api.Widget) {
          window.Trengo.Api.Widget.render();
        }
      }, 2500);
    } catch (error) {
      console.error("Authentication failed:", error);
      signOut();
    }
  };

  const { data: features = [] } = useQuery(
    ["retailerFeatures", userClaims?.retailerId],
    () => {
      if (userClaims?.retailerId) {
        return getRequestUI(`/retailer/feature/${userClaims.retailerId}`);
      }
      return [];
    },
    {
      enabled: !!userClaims?.retailerId,
    },
  );

  const session = {
    user: {
      name: userClaims.email,
      email: userClaims.email,
      image: "/path-to-avatar-image",
    },
  };

  const authentication = useMemo(() => {
    return {
      signIn: () => {},
      signOut: signOut,
    };
  }, []);

  const filteredNavigation = useMemo(() => {
    const isShippingIntelligenceEnabled = features.find(
      (f) => f.id === "shipping_intelligence",
    )?.enabled;

    return NAVIGATION.filter((navItem) => {
      if (
        userClaims?.userType === "storeManager" &&
        navItem.segment === SEGMENTS.LOCATIONS
      ) {
        return false;
      }

      if (
        [
          SEGMENTS.SHIPPING_INTELLIGENCE_HEADER,
          SEGMENTS.OVERVIEW,
          SEGMENTS.BENCHMARKS,
        ].includes(navItem.segment)
      ) {
        return isShippingIntelligenceEnabled;
      }

      return true;
    });
  }, [userClaims, features]);

  return (
    <AppProvider
      session={session}
      authentication={authentication}
      navigation={filteredNavigation}
      theme={demoTheme}
      branding={{
        logo: (
          <img
            src="/blackandblue.png"
            alt="Bringly Logo"
            style={{ maxWidth: "100px" }}
          />
        ),
        title: "",
      }}
    >
      <Outlet context={{ auth, userClaims }} />
    </AppProvider>
  );
}
