import React, { useState, useEffect } from "react";
import { Filters, MainBox } from "@shared/components/lib/index";
import { getRequestUI } from "common-utils/utils/api";
import { DateTime } from "luxon";
import CustomPieChart from "../components/CustomPieChart";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { PieChart } from "@mui/x-charts";
import { useQuery } from "react-query";

const RetailerBigdata = (userClaims) => {
  const date = new Date();
  const [selectedStartPickUpDateFilter, setSelectedStartPickUpDateFilter] =
    useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [selectedEndPickUpDateFilter, setSelectedEndPickUpDateFilter] =
    useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  const isRequestEnabled = false;

  const bigdataAnalytics = useQuery(
    ["bigdata-analytics"],
    getBigdataAnalytics,
    {
      enabled: isRequestEnabled,
    },
  );
  useEffect(() => {
    if (isRequestEnabled) {
      bigdataAnalytics.refetch();
    }
  }, [
    selectedStartPickUpDateFilter,
    selectedEndPickUpDateFilter,
    isRequestEnabled,
  ]);

  const isBigdataReady = !!bigdataAnalytics.data;
  console.log(
    "isBigdataReady",
    isBigdataReady,
    JSON.stringify(
      bigdataAnalytics.data && bigdataAnalytics.data.perCarrier,
      null,
      4,
    ),
  );

  function getBigdataAnalytics() {
    return getRequestUI("/retailer/analytics", getFilterData());
  }

  const getFilterData = () => ({
    startDate: DateTime.fromJSDate(selectedStartPickUpDateFilter).toFormat(
      "yyyy-MM-dd",
    ),
    endDate: DateTime.fromJSDate(selectedEndPickUpDateFilter).toFormat(
      "yyyy-MM-dd",
    ),
  });

  return (
    <>
      <MainBox>
        <div style={{ padding: 20 }}>
          <Filters
            setTitle={"Bigdata"}
            selectedStartPickUpDateFilter={selectedStartPickUpDateFilter}
            setSelectedStartPickUpDateFilter={setSelectedStartPickUpDateFilter}
            selectedEndPickUpDateFilter={selectedEndPickUpDateFilter}
            setSelectedEndPickUpDateFilter={setSelectedEndPickUpDateFilter}
            hideApplyFilter={true}
            hideExport={true}
          />
        </div>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={3}>
            <Typography variant="h3">Analytics</Typography>
          </Grid>
        </Grid>

        {isBigdataReady && (
          <>
            <Grid item xs={12} alignContent="center">
              <Typography align="center">
                Total orders: {bigdataAnalytics.data.total}
              </Typography>
            </Grid>
            <Grid item xs={12} alignContent="center">
              <PieChart
                series={[{ data: bigdataAnalytics.data.perCarrier }]}
                width={800}
                height={200}
              />
              <Typography align="center">Per carriers</Typography>
            </Grid>
            <Grid item xs={12} alignContent="center">
              <PieChart
                series={[{ data: bigdataAnalytics.data.timeframeStatus }]}
                width={800}
                height={200}
              />
              <Typography align="center">
                On-time performance (time frame only)
              </Typography>
            </Grid>
            <Grid item xs={12} alignContent="center">
              <PieChart
                series={[{ data: bigdataAnalytics.data.top10cities }]}
                width={800}
                height={200}
              />
              <Typography align="center">
                Shipments for the top 10 cities
              </Typography>
            </Grid>
            <Grid item xs={12} alignContent="center">
              <PieChart
                series={[{ data: bigdataAnalytics.data.firstAttemptResults }]}
                width={800}
                height={200}
              />
              <Typography align="center">Hit rate (first attempt)</Typography>
            </Grid>
            <Grid item xs={12} alignContent="center">
              <PieChart
                series={[{ data: bigdataAnalytics.data.timeline }]}
                width={800}
                height={200}
              />
              <Typography align="center">
                On-time performance & culpability late shipments
              </Typography>
            </Grid>
          </>
        )}
      </MainBox>
    </>
  );
};

export default RetailerBigdata;
